import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

import { FontAwesomeIcons, InputType } from '../../constants/ComponentEnums.jsx';
import { REGEX } from '../../constants/RegexEnums.js';
import Button from '../Button/Button.jsx';
import Icon from '../FontAwesome/Icon.jsx';

const CustomFileInput = forwardRef(
  (
    {
      id,
      hidden,
      buttonColor,
      textColor,
      buttonText,
      func,
      fileType = undefined,
      icon,
      dataFrom = undefined,
      removeState,
      changeTxt = false,
      disabled,
      chat = false,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const userInfo = useSelector((state) => state.user.getById);
    const [selectedFile, setSelectedFile] = useState(null);
    const [invalidImage, setInvalidImage] = useState('');
    const [accept, setAccept] = useState('');
    const [fileUrl, setFileUrl] = useState('');
    const fileInputRef = useRef(null);

    const MAX_FILE_SIZE = 10485760;
    const allowedImageExtensions = REGEX.ImageExtensions; // =>> fileType = Image , dataFrom = !undefined
    const allowedFileExtensions =
      dataFrom === 'Career' ? REGEX.CvExtensions : dataFrom === 'CSV' ? REGEX.CVS : REGEX.FileExtensions; // =>> fileType = File , dataFrom = !undefined
    const allowedExtensionsChat = REGEX.AllExtensions; // =>> fileType = undefined , dataFrom = undefined

    function getAllowedExtensionsRegex(allowedExtensions) {
      const regexString = allowedExtensions.toString();
      const extensions = regexString.match(/\.[a-z]+/gi);
      const result = extensions ? extensions.join(', ') : '';
      return result;
    }
    useEffect(() => {
      if (removeState) {
        setSelectedFile(null);
        setFileUrl('');
      }
    }, [removeState]);
    useEffect(() => {
      if (userInfo?.id !== undefined) {
        setFileUrl(userInfo?.userImage);
      }
    }, [userInfo]);

    useEffect(() => {
      switch (fileType) {
        case 'File':
          setAccept(getAllowedExtensionsRegex(allowedFileExtensions));
          break;
        case 'Image':
          setAccept(getAllowedExtensionsRegex(allowedImageExtensions));
          break;
        case 'Logo':
          setAccept(getAllowedExtensionsRegex(allowedImageExtensions));
          break;
        default:
          setAccept(getAllowedExtensionsRegex(allowedExtensionsChat));
          break;
      }
    }, []);

    useEffect(() => {
      if (invalidImage !== '') {
        toast.error(invalidImage, {
          onClose: () => {
            setInvalidImage('');
          },
        });
      }
    }, [invalidImage]);

    useImperativeHandle(ref, () => ({
      handleFileInsert: (e) => {
        handleFileInsert(e);
      },
    }));

    const handleFileInsert = (e) => {
      e.preventDefault();
      fileInputRef.current.click();
    };

    const isImage = (file) => allowedImageExtensions.exec(file);
    const isFile = (file) => allowedFileExtensions.exec(file);
    const handleFileInputChange = (e) => {
      const { id } = e.target;
      const file = e.target.files[0];
      function getExtension(url) {
        const splitUrl = url.split('.');
        const extension = splitUrl[splitUrl.length - 1];
        return extension.toLowerCase();
      }
      const convertToWebP = (img) => {
        return new Promise((resolve, reject) => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          if (!ctx) {
            reject(new Error('Failed to get canvas context'));
            return;
          }

          canvas.width = img.width;
          canvas.height = img.height;

          ctx.drawImage(img, 0, 0);

          canvas.toBlob(
            (blob) => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error('Failed to convert image to WebP'));
              }
            },
            'image/webp',
            0.9
          );
        });
      };
      const reader = new FileReader();
      if (fileUrl !== '' && dataFrom !== undefined) {
        if ((isImage(fileUrl) || fileUrl !== '') && !isImage(file?.name)) {
          setInvalidImage(t('fileUpload.invalid_image_extension'));
          return false;
        } else if (isFile(fileUrl) && !isFile(file?.name)) {
          setInvalidImage(t('fileUpload.invalid_file_career_extension'));
          return false;
        }
      }
      if (
        (fileType === 'Image' || dataFrom === undefined || fileType === 'Logo') &&
        isImage(file?.name) &&
        fileType !== 'File'
      ) {
        if (isImage(file?.name)) {
          if (!allowedImageExtensions.exec(file?.name)) {
            setInvalidImage(t('fileUpload.invalid_image_extension'));
            return false;
          }
          if (file.size > MAX_FILE_SIZE) {
            setInvalidImage(t('fileUpload.file_size_warning'));
            return false;
          }

          reader.onloadend = async () => {
            const image = new Image();
            const fileURL = e.target.files[0].name;
            const fileExtension = getExtension(fileURL);
            image.src = reader.result;

            image.onload = async () => {
              const width = image.width;
              const height = image.height;
              const maxWidth = 10000;
              const maxHeight = 10000;

              if (width > maxWidth || height > maxHeight) {
                setInvalidImage(
                  t('fileUpload.image_dimension_warning', {
                    maxWidth: maxWidth,
                    maxHeight: maxHeight,
                  })
                );
                func(false);
                setSelectedFile('');
                return false;
              }

              try {
                let webpBlob;
                let webpURL;

                if (fileExtension === 'webp') {
                  webpBlob = await fetch(reader.result).then((res) => res.blob());
                  webpURL = URL.createObjectURL(webpBlob);
                } else {
                  webpBlob = await convertToWebP(image);
                  webpURL = URL.createObjectURL(webpBlob);
                }

                const base64String = await new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onloadend = () => resolve(reader.result.split(',')[1]);
                  reader.onerror = reject;
                  reader.readAsDataURL(webpBlob);
                });

                setSelectedFile(fileURL);
                const parentFiles = {
                  imgSrc: webpURL,
                  base64String: base64String,
                  extension: 'webp',
                  fileName: fileURL,
                };
                func(parentFiles);
              } catch (error) {
                console.error('Error processing image:', error);
                func(false);
              }
            };

            setFileUrl(file?.name);
          };
        } else if (!isImage(file?.name) && dataFrom === 'Profile') {
          setInvalidImage(t('fileUpload.invalid_image_extension'));
          return false;
        } else if (!isImage(file?.name) && dataFrom === 'Career') {
          setInvalidImage(t('fileUpload.invalid_image_extension'));
          return false;
        }
      } else if ((fileType === 'File' || dataFrom === undefined) && isFile(file?.name) && fileType !== 'Image') {
        if (isFile(file?.name)) {
          if (file.size > MAX_FILE_SIZE) {
            setInvalidImage(t('fileUpload.file_size_warning'));
            return false;
          }

          switch (getExtension(file?.name)) {
            case 'pdf':
              setSelectedFile(<Icon icon={FontAwesomeIcons.faFilePdf} size={'2x'} />);
              break;
            default:
              setSelectedFile(<Icon icon={FontAwesomeIcons.faFileWord} size={'2x'} />);
              break;
          }
          reader.onloadend = () => {
            const fileURL = e.target.files[0].name;
            const base64String = reader.result.split(',')[1];
            const parentFile = {
              fileSrc: fileURL,
              base64String: base64String,
              extension: getExtension(fileURL),
              fileName: fileURL,
            };
            func(parentFile);
          };
          setFileUrl(file?.name);
        } else if (!isFile(file?.name) && dataFrom === 'Career') {
          setInvalidImage(t('fileUpload.invalid_file_career_extension'));
          return false;
        }
      } else if (!isFile(file?.name) && !isImage(file?.name) && dataFrom === undefined) {
        setInvalidImage(t('fileUpload.invalid_custom_extension'));
        return false;
      } else {
        if (isFile(file?.name) && fileType !== 'File') {
          setInvalidImage(t('fileUpload.invalid_image_extension'));
        } else if (isImage(file?.name) && fileType !== 'Image') {
          setInvalidImage(t('fileUpload.invalid_file_career_extension'));
        } else if (!isImage(file?.name) && !isFile(file?.name)) {
          if (fileType === 'File') {
            setInvalidImage(t('fileUpload.invalid_file_career_extension'));
          } else {
            setInvalidImage(t('fileUpload.invalid_image_extension'));
          }
        }
      }
      reader.readAsDataURL(file);
    };
    const customToastStyle = {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };

    return (
      <div className={`${disabled ? 'd-none' : ''}`}>
        <div className={`d-flex justify-content-center ${chat ? '' : 'mt-2'}`}>
          <input
            id={id}
            ref={fileInputRef}
            type={InputType.File}
            className={'d-none'}
            hidden={hidden}
            onChange={handleFileInputChange}
            accept={accept}
          />
          {!icon && !changeTxt && (
            <span role={'button'} onClick={handleFileInsert} className='text-muted' style={{ fontSize: '.7rem' }}>
              {!removeState
                ? selectedFile
                : fileType === 'Image'
                  ? t('fileUpload.upload_profile_picture')
                  : fileType === 'Logo'
                    ? t('company.uploadLogoTxt')
                    : t('fileUpload.upload_document')}
            </span>
          )}
        </div>
        <div className={`d-flex justify-content-center ${chat ? '' : 'mt-2'}`}>
          <div className='col-12 col-md-4'>
            <Button
              type={buttonColor}
              onClick={handleFileInsert}
              text={!icon ? buttonText : icon}
              textColor={textColor}
              icon={icon}
            />
          </div>
        </div>
        {invalidImage !== '' && (
          <ToastContainer
            id={'file_upload_component'}
            key={'file_upload_component'}
            style={customToastStyle}
            limit={1}
            autoClose={2000}
            closeOnClick
            theme='light'
          />
        )}
      </div>
    );
  }
);

export default CustomFileInput;
