import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import generateRandomKey from '../../hooks/useKeyGenerator';

function Icon({ icon, mt, ms, me, mb, mx, pe, px, p, rounded, border, size, color, style, onClick, role }) {
  let className = [
    mt ? `mt-${mt}` : '',
    ms ? `ms-${ms}` : '',
    me ? `me-${me}` : '',
    mb ? `mb-${mb}` : '',
    mx ? `mx-${mx}` : '',
    pe ? `pe-${pe}` : '',
    px ? `px-${px}` : '',
    border ? 'border' : '',
    rounded ? 'rounded-circle' : '',
    p ? `p-${p}` : '',
    size ? size : '',
    color ? color : '',
    style ? style : '',
  ];
  className = className.filter(Boolean).join(' ').trim();
  return (
    <FontAwesomeIcon
      key={generateRandomKey()}
      className={className}
      role={role}
      icon={icon}
      size={size}
      color={color}
      onClick={onClick}
    />
  );
}

export default Icon;
